import React from 'react'
import "./ButtonLoader.css"

export default function ButtonLoader() {
    return (
        <div className="button__loader">
            Loadeng...
        </div>
    )
}
